interface IUserOperation {
  callData: string;
  callGasLimit?: number;
  verificationGasLimit?: number;
  preVerificationGas?: number;
  maxFeePerGas?: number;
  maxPriorityFeePerGas?: number;
  paymasterAndData?: string;
}
export interface IUserOperationTemplate {
  id: string;
  name: string;
  userOpObj: IUserOperation;
}

export const sendTokens: IUserOperationTemplate = {
  id: "sendTokens",
  name: "Send native token",
  userOpObj: {
    callData:
      "b61d27f600000000000000000000000050504ab483c9bde3af9700b5fe77a860d81b3e4f00000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000000000000000600000000000000000000000000000000000000000000000000000000000000000",
  },
};

export const transferErc20: IUserOperationTemplate = {
  id: "transferErc20",
  name: "Transfer erc20 token",
  userOpObj: {
    callData:
      "b61d27f60000000000000000000000002d7882bedcbfddce29ba99965dd3cdf7fcb10a1e000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000600000000000000000000000000000000000000000000000000000000000000044a9059cbb00000000000000000000000050504ab483c9bde3af9700b5fe77a860d81b3e4f000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000",
  },
};

export const setValueWithCustomPaymaster: IUserOperationTemplate = {
  id: "setValueWithCustomPaymaster",
  name: "Set value with custom paymaster",
  userOpObj: {
    callData:
      "b61d27f6000000000000000000000000dc5fd9220511a7211719dd8206ec2d686054bcc000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000060000000000000000000000000000000000000000000000000000000000000002455241077000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000",
    paymasterAndData:
      "6dFb4b4800dd6Ab8cc142d20DC2f4de2bF5c9b0A0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000006492d7d9dbaab7fcd442c32259de3c1a450c3a79d134fc813702dd9689d0415261e0c9004a39173aa923a93df22b1067414927405f4c92ab2692e858570605804ca89d4c1c",
  },
};

export const getNft: IUserOperationTemplate = {
  id: "getNft",
  name: "Mint NFT",
  userOpObj: {
    callData:
      "b61d27f600000000000000000000000000005ea00ac477b1030ce78506496e8c2de24bf5000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000600000000000000000000000000000000000000000000000000000000000000084161ac21f000000000000000000000000fd8ec18d48ac1f46b600e231da07d1da8209ceef0000000000000000000000000000a26b00c1f0df003000390027140000faa7190000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000",
  },
};

export const sendRawTransaction: IUserOperationTemplate = {
  id: "sendTransaction",
  name: "Send transaction",
  userOpObj: {
    callData: "",
  },
};

export const sendContractTransaction: IUserOperationTemplate = {
  id: "sendContractTransaction",
  name: "Send Contract transaction",
  userOpObj: {
    callData: "",
  },
};
